import React from "react";
import { Eye, RefreshCcw, FilePlus } from "react-feather";
import { Link } from "react-router-dom";
import IconButton from "@src/components/shared/IconButton";
import Avatar from "@src/@core/components/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getMessageSeverity = (status) => {
  switch (status) {
    case "pending":
      return "warning";
    case "sent":
      return "success";
    case "fail":
      return "danger";
    default:
      return "info";
  }
};

const getMessageSeverityName = (status) => {
  switch (status) {
    case "notGenerated":
      return "Not Generated";
    case "notRequired":
      return "Not Required";
    default:
      return status.charAt(0).toUpperCase() + status.slice(1);
  }
};

const getIcon = (status) => {
  switch (status) {
    case "notGenerated":
      return <FilePlus className="font-medium-3 text-body" />;
    case "failed":
    case "pending":
    case "sent":
      return <RefreshCcw className="font-medium-3 text-body" />;
    default:
      return null; // No icon for "NotRequired"
  }
};

const MessageStatus = ({
  status,
  linkUrl,
  id,
  label,
  typeIcon,
  handelGenerateMessage = () => {},
}) => {
  return (
    <div className="transaction-item">
      <div className="d-flex">
        <Avatar
          className="rounded"
          color={"light-" + getMessageSeverity(status)}
          icon={
            <FontAwesomeIcon icon={typeIcon} style={{ fontSize: "1.3rem" }} />
          }
        />
        <div>
          <h6 className="transaction-title">{label}</h6>
          <small>{getMessageSeverityName(status)}</small>
        </div>
      </div>
      <div className="fw-bolder text-success">
        {" "}
        {id ? (
          <Link to={linkUrl}>
            <Eye
              className="font-medium-3 text-body"
              title="Show"
              style={{ marginLeft: 10 }}
            />
          </Link>
        ) : null}
        {status !== "notRequired" && (
          <IconButton
            icon={getIcon(status)}
            tooltip={status == "notGenerated" ? "Generate" : "ReGenerate"}
            tooltipOptions={{ position: "left" }}
            onClick={handelGenerateMessage}
          />
        )}
      </div>
    </div>
  );
};

export default MessageStatus;
