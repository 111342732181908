// ** Router imports
import { lazy } from "react";
import * as reactDom from "react-router-dom";
import config from "@src/configs/themeConfig";
// ** GetRoutes
// import { getRoutes } from "./routes";
import { getRoutes } from "@app/layout";
// ** Hooks Imports
import { useLayout, layoutConfig, useUser } from "@app/layout";
import navigation from "../navigation/horizontal";
const { Navigate } = reactDom;
const Home = lazy(() => import("../views/Home"));

// ** Merge Routes
import PrlMessagesRoutes from "@src/views/prlMessages/route";
import LdmMessagesRoutes from "@src/views/ldmMessages/route";
import ApisMessagesRoutes from "@src/views/apisMessages/route";
import LoadSheetsRoutes from "@src/views/loadSheets/route";
import MessageTemplatesRoutes from "@src/views/messageTemplates/route";
import MessageSendersRoutes from "@src/views/messageSenders/route";
import ApisSettingsRoutes from "@src/views/apisSettings/route";
import MessageAddressesRoutes from "@src/views/messageAddresses/route";
import NotificationsRoutes from "@src/views/notifications/route";
import StationMailsRoutes from "@src/views/stationMails/route";
import FlightsRoutes from "@src/views/flights/route";

const baseRoute = config.app.baseRoute;

const mainRoutes = [
  {
    path: baseRoute,
    index: true,
    element: <Home />,
  },
  ...PrlMessagesRoutes.map((r) => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...LdmMessagesRoutes.map((r) => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...ApisMessagesRoutes.map((r) => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...LoadSheetsRoutes.map((r) => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...MessageTemplatesRoutes.map((r) => ({
    ...r,
    path: `${baseRoute}${r.path}`,
  })),
  ...MessageSendersRoutes.map((r) => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...StationMailsRoutes.map((r) => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...ApisSettingsRoutes.map((r) => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...MessageAddressesRoutes.map((r) => ({
    ...r,
    path: `${baseRoute}${r.path}`,
  })),
  ...NotificationsRoutes.map((r) => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...FlightsRoutes.map((r) => ({ ...r, path: `${baseRoute}${r.path}` })),
];

const Router = () => {
  const { useRoutes } = reactDom;
  const { layout } = useLayout({ ...layoutConfig });

  const allRoutes = getRoutes("horizontal", mainRoutes, reactDom, {
    navigations: [...navigation],
  });
  // const routes = reactDom.useRoutes(mainRoutes);
  const routes = useRoutes([...allRoutes]);

  return routes;
};

export default Router;
