import React, { Fragment, memo, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Label,
  InputGroup,
  Button,
} from "reactstrap";
import { useGetLdmMessageQuery } from "@src/redux/messages";
import { useParams } from "react-router-dom";
import { ReadonlyInput } from "@src/components/inputs";
import Loader from "@src/components/shared/Loader";
import {
  faPlane,
  faPlaneDeparture,
  faPlaneArrival,
  faCalendar,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { formatDateTime } from "@src/utility/Utils";
import { Tag } from "primereact/tag";
import CopyToClipboard from "@src/components/shared/CopyToClipboard";
import ResendMessageModel from "../common/ResendMessageModel";
import { Send } from "react-feather";

const getMessageSeverity = (status) => {
  switch (status) {
    case "pending":
      return "warning";
    case "sent":
      return "success";
    case "fail":
      return "danger";
    default:
      return "info";
  }
};

const Details = () => {
  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  const { data, isFetching, error } = useGetLdmMessageQuery(id);

  return (
    <Fragment>
      {isFetching && <Loader />}
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">Flight Details</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="info-container">
            <br />
            {data ? (
              <>
                <Row>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Flight Number"
                        name="flightNo"
                        icon={faPlane}
                        defaultValue={data.flightNo}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Departure"
                        name="departure"
                        icon={faPlaneDeparture}
                        defaultValue={data.departure}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="STD"
                        name="std"
                        icon={faCalendar}
                        defaultValue={
                          data.std && formatDateTime(new Date(data.std))
                        }
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="STD UTC"
                        name="stdutc"
                        icon={faCalendar}
                        defaultValue={
                          data.stdutc && formatDateTime(new Date(data.stdutc))
                        }
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1" style={{ visibility: "hidden" }}>
                      <ReadonlyInput label="helper" icon={faPlaneArrival} />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Arrival"
                        name="arrival"
                        icon={faPlaneArrival}
                        defaultValue={data.arrival}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="STA"
                        name="sta"
                        icon={faCalendar}
                        defaultValue={
                          data.sta && formatDateTime(new Date(data.sta))
                        }
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="STA UTC"
                        name="stautc"
                        icon={faCalendar}
                        defaultValue={
                          data.stautc && formatDateTime(new Date(data.stautc))
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col>
                  <p>{error ? error : "No data available."}</p>
                </Col>
              </Row>
            )}
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">LDM Message Details</CardTitle>
          <div className="flex flex-wrap gap-2">
            <Button.Ripple
              outline
              type="button"
              onClick={() => {
                setVisible(true);
              }}
              color="warning"
            >
              <Send size={18} />
              &nbsp; Resend
            </Button.Ripple>
          </div>
        </CardHeader>
        <CardBody>
          <div className="info-container">
            <br />
            {data && (
              <>
                <Row>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Message Time"
                        name="messageTime"
                        icon={faCalendar}
                        defaultValue={
                          data.messageTime &&
                          formatDateTime(new Date(data.messageTime))
                        }
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      <Label className="form-label" for="messageStatus">
                        <h6>Message Status</h6>
                      </Label>
                      <InputGroup>
                        <Tag
                          value={data.status}
                          severity={getMessageSeverity(data.status)}
                          className="text-capitalize"
                        />
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={data.status === "sent" ? "6" : "12"}
                    md={data.status === "sent" ? "6" : "12"}
                    className="mb-1"
                  >
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Message Content"
                        name="MessageContent"
                        icon={faMessage}
                        type="textarea"
                        rows="15"
                        defaultValue={data.text}
                      />
                      <CopyToClipboard
                        value={data.text}
                        defaultMsg="Click to copy"
                        successMsg="Copied to clipboard!"
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      {data.status === "sent" && (
                        <div id="ack">
                          <ReadonlyInput
                            label="Ack"
                            name="Ack"
                            icon={faMessage}
                            type="textarea"
                            rows="15"
                            defaultValue={data.ack}
                          />
                          <CopyToClipboard
                            value={data.ack}
                            defaultMsg="Click to copy"
                            successMsg="Copied to clipboard!"
                          ></CopyToClipboard>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </CardBody>
      </Card>
      {data && (
        <ResendMessageModel
          visible={visible}
          toggle={() => setVisible(false)}
          messageId={data.id}
        />
      )}
    </Fragment>
  );
};

export default memo(Details);
