import { Fragment, memo, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
} from "reactstrap";
import { useGetPrlMessageQuery } from "@src/redux/messages";
import { useParams } from "react-router-dom";
import CopyToClipboard from "@src/components/copyToClipboard";
import { ReadonlyInput } from "@src/components/inputs";
import {
  faPlane,
  faPlaneDeparture,
  faPlaneArrival,
  faCalendar,
  faMessage,
  faHourglassHalf,
  faEnvelopeCircleCheck,
  faCircleExclamation,
  faHashtag,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "@src/components/shared/Loader";
import { TabView, TabPanel } from "primereact/tabview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateTime } from "@src/utility/Utils";
import ResendMessageModel from "./ResendMessageModel";
import { Send } from "react-feather";

const getSeverityIcon = (status) => {
  switch (status) {
    case "pending":
      return faHourglassHalf;
    case "sent":
      return faEnvelopeCircleCheck;
    case "fail":
      return faCircleExclamation;
    default:
      return faMessage;
  }
};

const getSeverityClass = (status) => {
  switch (status) {
    case "pending":
      return "text-warning";
    case "sent":
      return "text-success";
    case "fail":
      return "text-danger";
    default:
      return "text-info";
  }
};

const Details = () => {
  const [visible, setVisible] = useState(false);
  const { flightId, partNo } = useParams();
  const { data, isFetching } = useGetPrlMessageQuery(flightId);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (partNo && data && data.messageParts.length >= partNo) {
      setActiveIndex(parseInt(partNo) - 1);
    }
  }, [partNo, data]);
  const tabHeaderTemplate = (part, options) => {
    const severityClass = getSeverityClass(part.status);
    return (
      <div
        className={`flex align-items-center gap-2 p-1 ${severityClass}`}
        style={{ cursor: "pointer" }}
        onClick={options.onClick}
        key={"part" + part.partNo}
      >
        <FontAwesomeIcon
          icon={getSeverityIcon(part.status)}
          style={{ fontSize: "0.8rem" }}
        />
        <span className="text-uppercase font-bold white-space-nowrap">
          {" "}
          Part-{part.partNo}
        </span>
      </div>
    );
  };

  return (
    <Fragment>
      {isFetching && <Loader />}
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">Flight Details</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="info-container">
            <br />
            {data ? (
              <>
                <Row>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Flight Number"
                        name="flightNo"
                        icon={faPlane}
                        defaultValue={data.flightNo}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Departure"
                        name="departure"
                        icon={faPlaneDeparture}
                        defaultValue={data.departure}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="STD"
                        name="std"
                        icon={faCalendar}
                        defaultValue={
                          data.std && formatDateTime(new Date(data.std))
                        }
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="STD UTC"
                        name="stdutc"
                        icon={faCalendar}
                        defaultValue={
                          data.stdutc && formatDateTime(new Date(data.stdutc))
                        }
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1" style={{ visibility: "hidden" }}>
                      <ReadonlyInput label="helper" icon={faPlaneArrival} />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Arrival"
                        name="arrival"
                        icon={faPlaneArrival}
                        defaultValue={data.arrival}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="STA"
                        name="sta"
                        icon={faCalendar}
                        defaultValue={
                          data.sta && formatDateTime(new Date(data.sta))
                        }
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="STA UTC"
                        name="stautc"
                        icon={faCalendar}
                        defaultValue={
                          data.stautc && formatDateTime(new Date(data.stautc))
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col>
                  <p>{"No data available."}</p>
                </Col>
              </Row>
            )}
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">Prl Message Details</CardTitle>
          <div className="flex flex-wrap gap-2">
            <Button.Ripple
              outline
              type="button"
              onClick={() => {
                setVisible(true);
              }}
              color="warning"
            >
              <Send size={18} />
              &nbsp; Resend
            </Button.Ripple>
          </div>
        </CardHeader>
        <CardBody>
          <div className="info-container">
            <br />
            {data ? (
              <>
                <Row>
                  <Col lg="6" md="6" className="mb-1">
                    {" "}
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Inbound Pax Count"
                        name="inboundPassengersCount"
                        icon={faHashtag}
                        defaultValue={data.inboundPassengersCount}
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Onward Pax Count"
                        name="onwardPassengersCount"
                        icon={faHashtag}
                        defaultValue={data.onwardPassengersCount}
                      />
                    </div>
                  </Col>

                  <TabView
                    scrollable
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                  >
                    {data.messageParts.map((part, index) => (
                      <TabPanel
                        rightIcon="fa-solid fa-building"
                        leftIcon="pi pi-calendar mr-2"
                        header={`Part ${index + 1}`}
                        headerTemplate={(options) =>
                          tabHeaderTemplate(part, options)
                        }
                        key={index}
                      >
                        <Row>
                          <Col lg="12" md="12" className="mb-1">
                            <ReadonlyInput
                              label="Message Time"
                              name="MessageTime"
                              icon={faCalendar}
                              defaultValue={
                                part.messageTime &&
                                formatDateTime(new Date(part.messageTime))
                              }
                            />
                          </Col>
                          <Col lg="6" md="6" className="mb-1">
                            {" "}
                            <div className="mb-1">
                              <ReadonlyInput
                                label="Inbound Pax Count"
                                name="inboundPassengersCount"
                                icon={faHashtag}
                                defaultValue={part.inboundPassengersCount}
                              />
                            </div>
                          </Col>
                          <Col lg="6" md="6" className="mb-1">
                            <div className="mb-1">
                              <ReadonlyInput
                                label="Onward Pax Count"
                                name="onwardPassengersCount"
                                icon={faHashtag}
                                defaultValue={part.onwardPassengersCount}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={part.status === "sent" ? "6" : "12"}
                            md={part.status === "sent" ? "6" : "12"}
                            className="mb-1"
                          >
                            <div className="mb-1">
                              <ReadonlyInput
                                label="Message Content"
                                name="MessageContent"
                                icon={faMessage}
                                type="textarea"
                                rows="15"
                                defaultValue={part.text}
                              />
                              <CopyToClipboard
                                value={part.text}
                                defaultMsg="Click to copy"
                                successMsg="Copied to clipboard!"
                              />
                            </div>
                          </Col>
                          <Col lg="6" md="6" className="mb-1">
                            <div className="mb-1">
                              {part.status === "sent" && (
                                <div id="ack">
                                  <ReadonlyInput
                                    label="Ack"
                                    name="Ack"
                                    icon={faMessage}
                                    type="textarea"
                                    rows="15"
                                    defaultValue={part.ack}
                                  />
                                  <CopyToClipboard
                                    value={part.ack}
                                    defaultMsg="Click to copy"
                                    successMsg="Copied to clipboard!"
                                  ></CopyToClipboard>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </TabPanel>
                    ))}
                  </TabView>
                </Row>
              </>
            ) : (
              <Row>
                <Col>
                  <p>No data available</p>
                </Col>
              </Row>
            )}
          </div>
        </CardBody>
      </Card>
      {data && (
        <ResendMessageModel
          visible={visible}
          toggle={() => setVisible(false)}
          flightId={flightId}
          messageParts={data.messageParts}
          selectedMessagePart={partNo}
        />
      )}
    </Fragment>
  );
};

export default memo(Details);
